<template>
  <td class="custom-table__column custom-table-edit">
    <div class="custom-table-edit__icon">
      <SvgIcon
        :name="icon"
        width="32"
        height="32"
        @click="popupStateHandler(true)"
      />
    </div>

    <CustomPopup
      class="custom-table-edit__popup"
      :pop-up-state="isShowPopup"
      @closePopUp="popupStateHandler(false)"
    >
      <div @click="popupStateHandler(false)">
        <slot />
      </div>
    </CustomPopup>
  </td>
</template>

<script>
import CustomPopup from '~/components/elements/CustomPopup';
export default {
  name: 'CustomTableColEdit',
  components: {CustomPopup},
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShowPopup: false,
    }
  },
  methods: {
    popupStateHandler(state) {
      setTimeout(() => {
        this.isShowPopup = state
      }, 0)
    },
  },
}
</script>
